import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'

import Typography from '@material-ui/core/Typography';
import { Grid } from "@material-ui/core";

import LoginBox from 'Components/Body/MoreInfo/Login'
import LoadingDateBox from 'Components/Body/MoreInfo/LoadingDate'
import PalletsBox from 'Components/Body/MoreInfo/Pallete'
import LoadingPlaceBox from 'Components/Body/MoreInfo/LoadingPlace'
import BillingBox from 'Components/Body/MoreInfo/Billing'
import AdditionalNotesBox from 'Components/Body/MoreInfo/AdditionalNotes'
import MethodOfPaymentBox from 'Components/Body/MoreInfo/MethodOfPayment'
import ConfirmPurchaseBox from 'Components/Body/MoreInfo/ConfirmPurchase'
import { PriceBoxState } from "Components/Commons/PriceBox";
import { CustomerType } from 'Components/Commons/Const/Type'
import ConfirmationDialog from "Components/Commons/ConfirmationDialog";

import { setPopup } from 'Actions/pageActions'
import { calculatePrice } from "Actions/priceActions";
import { setValue } from "Actions/repositoryActions";
import { setInputValue } from "Actions/inputActions";
import { redirrect } from 'Actions/pageActions'

import Translations from 'Singleton/Translations'
import MediaType from "../../libs/MediaType";
import styles from 'Components/style'

import { MetaData, handleMetaData } from '../../Components/Commons/Const/MetaData'


class container extends React.Component {
	constructor(props) {
		super(props);
		this.createPalleteBoxs = this.createPalleteBoxs.bind(this);
		this.additionalNotes = this.additionalNotes.bind(this);
		this.placeFromRef = React.createRef();
		this.placeToRef = React.createRef();
	}

	componentWillMount() {
		document.title = MetaData.mainPage.title;
		handleMetaData();
	}

	state = {
		isLoadingDateValid: false,
		isNipValid: true,
		confirmationDialogOpened: false,
		postCodeFromChanged: '',
		postCodeToChanged: '',
		addressComponentToChange: {}
	}

	styles = {
		container: {
			padding: '45px 200px',
		},
		containerPure: {
			padding: '45px',
		},
		containerMobile: {
			padding: '15px',
		}
	}



	additionalNotes() {
		if (this.props.inputs.details === undefined || this.props.inputs.details === "") {
			return "";
		}
		return (
			<Grid item style={
				MediaType.isMobile ?
					styles.mobileBox :
					{}
			}>
				<AdditionalNotesBox
					text={this.props.inputs.details}
				/>
			</Grid>
		);
	}

	createPalleteBoxs() {
		var input = this.props.inputs;
		var arr = []
		for (var i = 0; i < this.props.paletsCount; i++) {
			var id = `more-info-pallete-${i}`;
			var title = this.props.paletsCount < 2 ? Translations.get('palette') : `${Translations.get('palette')} ${i + 1}`;
			var sizeId = `pallets_size_${i}`;
			var heightId = `palet_height_${i}`;
			var weightId = `palet_weight_${i}`;
			const lengthId = `palet_length_${i}`;
			const widthId = `palet_width_${i}`;

			var customPalleteId = this.props.pallets.findIndex(p => !!p.isCustom);
			var palleteId = input[sizeId] === 'custom' ? customPalleteId : input[sizeId];

			var pallete = this.props.pallets[palleteId];
			let length = pallete.isCustom ? input[lengthId] : pallete.length / 10;
			let width = pallete.isCustom ? input[widthId] : pallete.width / 10;

			var label = `${pallete.type} ${length} x ${width} x ${input[heightId]} cm ${input[weightId]} kg`
			arr.push(
				<Grid item key={i} style={
					MediaType.isMobile ?
						styles.mobileBox :
						{}
				} >
					<PalletsBox
						id={id}
						title={title}
						value={label}
					/>
				</Grid>
			);
		}
		return arr;
	}

	getLoginBox() {
		if (this.props.userToken && this.props.userEmail) {
			return '';
		}
		return (
			<Grid item style={
				MediaType.isMobile ?
					styles.mobileBox :
					{}
			} >
				<LoginBox
					onClick={() => {
						this.props.setPopup('login');
					}}
				/>
			</Grid>
		)
	}

	loadingDateBoxValidChange = (value) => {
		this.setState({
			...this.state,
			isLoadingDateValid: value,
		})
	}

	getContainerStyle = () => {
		switch (MediaType.type) {
			case MediaType.DESKTOP: return this.styles.container;
			case MediaType.MOBILE: return this.styles.containerMobile;
			default: return this.styles.containerPure;
		}
	}

	onNipValidChange = (value) => {
		this.setState({
			...this.state,
			isNipValid: true,
		})
	}

	handleConfirmation(confirmed) {
		if (confirmed) {
			if (this.state.postCodeFromChanged) {
				this.props.inputs.post_code_from = this.state.postCodeFromChanged;
			}
			else if (this.state.postCodeToChanged) {
				this.props.inputs.post_code_to = this.state.postCodeToChanged;
			}
			this._calculatePrice();
		}
		else {
			if (this.state.postCodeFromChanged) {
				this.placeFromRef.wrappedInstance.updatePostCode(this.props.inputs.post_code_from);
			}
			else if (this.state.postCodeToChanged) {
				this.placeToRef.wrappedInstance.updatePostCode(this.props.inputs.post_code_to);
			}
		}
		this.setState({
			confirmationDialogOpened: false,
			postCodeFromChanged: '',
			postCodeToChanged: '',
		});
	}

	handlePostCodeFromChange(postCodeFromChanged) {
		this.setState({ postCodeFromChanged, confirmationDialogOpened: true });
	}

	handlePostCodeToChange(postCodeToChanged) {
		this.setState({ postCodeToChanged, confirmationDialogOpened: true });
	}

	handleCountryFromChange(countryChanged) {
		this.props.inputs.country_from = countryChanged;
		this.props.inputs.post_code_from = '';
		this.props.inputs['more-info-loading-place-country'] = countryChanged;
		this.props.inputs['more-info-loading-place-city'] = '';
		this.props.inputs['more-info-loading-place-street'] = '';
		this.props.setInputValue('country_from', countryChanged);
		this.checkCountryConditions(this.placeToRef);

	}

	handleCountryToChange(countryChanged) {
		this.props.inputs.country_to = countryChanged;
		this.props.inputs.post_code_to = '';
		this.props.inputs['more-info-unloading-place-country'] = countryChanged;
		this.props.inputs['more-info-unloading-place-city'] = '';
		this.props.inputs['more-info-unloading-place-street'] = '';
		this.props.setInputValue('country_to', countryChanged);
		this.checkCountryConditions(this.placeFromRef);
	}

	checkCountryConditions(ref) {
		if (this.props.inputs.country_from === "PL" && this.props.inputs.country_to === "PL") {
			ref.wrappedInstance.updateFlag('DE');
		} else if (this.props.inputs.country_from !== "PL" && this.props.inputs.country_to !== "PL") {
			ref.wrappedInstance.updateFlag('PL');
		}
	}

	_calculatePrice() {
		this.props.setRepositoryValue("priceBoxState", PriceBoxState.AWAIT);
		const sender = this.props.inputs.sender;
		calculatePrice(this.props, this.props.translations)
			.then(res => {
				this.props.setRepositoryValue("price", res.body);
				var priceState = this.state.priceBoxState;
				if (priceState !== PriceBoxState.AWAIT) {
					return;
				}
				if (res.body.discount > 0) {
					priceState = PriceBoxState.COMPLETE_VOUCHER;
				} else if (sender === "private") {
					priceState = PriceBoxState.COMPLETE;
				} else {
					priceState = PriceBoxState.COMPLETE_PLN;
				}
				this.props.setRepositoryValue("priceBoxState", priceState);
			})
			.catch(err => {
				console.log({ err })
			});
	}

	render() {
		var data = this.props.inputs;
		if (!data || !data['country_from'] ||
			!data['post_code_from'] ||
			!data['country_to'] ||
			!data['post_code_to'] ||
			!data['palet_height_0']) {
			setTimeout(() => {
				this.props.redirrect('/', this.props.history)
			}, 500);
			return "";
		}

		const streetFrom = [
			this.props.inputs.street_from,
			this.props.inputs.streetNumber_from,
		].join(' ').trim();

		const streetTo = [
			this.props.inputs.street_to,
			this.props.inputs.streetNumber_to,
		].join(' ').trim();

		return (
			<div style={this.getContainerStyle()}>
				<ConfirmationDialog
					hideCancel={false}
					open={this.state.confirmationDialogOpened}
					onClose={this.handleConfirmation.bind(this)}
					title="Potwierdź akcję"
					text="Możliwa zmiana ceny">
				</ConfirmationDialog>
				<Typography variant='display1' paragraph>
					{Translations.get('more_info_0')}
				</Typography>
				<Typography variant='body1' paragraph>
					{Translations.get('more_info_1')}
				</Typography>
				<Grid container>
					{this.getLoginBox()}
					<Grid item>
						<LoadingDateBox
							id="more-info-date"
							onValidChange={this.loadingDateBoxValidChange}
							country={this.props.inputs.country_from === 'PL' ? this.props.inputs.country_to : this.props.inputs.country_from}
						/>
					</Grid>
					{this.createPalleteBoxs()}
					<Grid item>
						<LoadingPlaceBox
							title={Translations.get('loading_place')}
							id="more-info-loading-place"
							street={streetFrom}
							raw={this.props.inputs.raw_from}
							ref={(ref) => this.placeFromRef = ref}
							postCode={this.props.inputs.post_code_from}
							country={this.props.inputs.country_from}
							city={this.props.inputs.city_from}
							onCountryChange={e => this.handleCountryFromChange(e)}
							onPostCodeChange={e => this.handlePostCodeFromChange(e)}
							isCarLiftCheckbox={this.props.inputs.country_from === 'IT'}
						/>
					</Grid>
					<Grid item>
						<LoadingPlaceBox
							title={Translations.get('unloading_place')}
							id="more-info-unloading-place"
							street={streetTo}
							raw={this.props.inputs.raw_to}
							ref={(ref) => this.placeToRef = ref}
							city={this.props.inputs.city_to}
							postCode={this.props.inputs.post_code_to}
							country={this.props.inputs.country_to}

							onCountryChange={e => this.handleCountryToChange(e)}
							onPostCodeChange={e => this.handlePostCodeToChange(e)}
							isCarLiftCheckbox={this.props.inputs.country_to === 'IT'}
						/>
					</Grid>
					<Grid item style={
						MediaType.isMobile ?
							styles.mobileBox :
							{}
					}>
						<BillingBox
							id="more-info-billing"
							isCompany={this.props.inputs.sender === CustomerType.COMPANY}
							onNipValidChange={this.onNipValidChange}
						/>
					</Grid>
					{this.additionalNotes()}
					<Grid item style={
						MediaType.isMobile ?
							styles.mobileBox :
							{}
					}>
						<MethodOfPaymentBox
							id="more-info-payment"
						/>
					</Grid>
					<Grid item style={
						MediaType.isMobile ?
							styles.mobileBox :
							{}
					}>
						<ConfirmPurchaseBox
							validation={this.validate.bind(this)}
						/>
					</Grid>
				</Grid>
			</div>
		);
	}

	// Validation functions //
	validate() {
		var data = this.props.inputs;
		const inputValid = this.props.inputValid;
		if (data &&
			data['country_from'] &&
			data['post_code_from'] &&
			data['country_to'] &&
			data['post_code_to'] &&
			data['palet_height_0'] &&
			data['more-info-date-date'] &&
			this.state.isLoadingDateValid &&
			// data['more-info-date-time-start'] &&
			// data['more-info-date-time-end'] &&
			data['more-info-loading-place-city'] &&
			data['more-info-loading-place-company'] &&
			data['more-info-loading-place-name'] &&
			inputValid['more-info-loading-place-name'] &&
			data['more-info-loading-place-street'] &&
			inputValid['more-info-loading-place-street'] &&
			data['more-info-loading-place-surname'] &&
			inputValid['more-info-loading-place-surname'] &&
			data['more-info-loading-place-tel'] &&
			data['more-info-unloading-place-city'] &&
			data['more-info-unloading-place-company'] &&
			data['more-info-unloading-place-name'] &&
			inputValid['more-info-unloading-place-name'] &&
			data['more-info-unloading-place-street'] &&
			inputValid['more-info-unloading-place-street'] &&
			data['more-info-unloading-place-surname'] &&
			inputValid['more-info-unloading-place-surname'] &&
			data['more-info-unloading-place-tel'] &&
			data['more-info-billing-radio-data']
		) {
			console.log("validation");
			var billingValid = false;
			if (data['more-info-billing-radio-data'] === 'loading' ||
				data['more-info-billing-radio-data'] === 'unloading') {
				billingValid = true;
			}
			else if (data['more-info-billing-city'] &&
				data['more-info-billing-country'] &&
				data['more-info-billing-postcode'] &&
				data['more-info-billing-street'] &&
				(
					(data['more-info-billing-name'] &&
						inputValid['more-info-billing-street'] &&
						inputValid['more-info-billing-name'] &&
						data['more-info-billing-surname'] &&
						inputValid['more-info-billing-surname'] &&
						data['more-info-billing-radio-data'] === "other" &&
						data['more-info-billing-tel'])
					|| this.props.isVatZero)
			) {
				billingValid = true;
			}
			if (data['sender'] === 'company') {
				billingValid = billingValid && (this.state.isNipValid || this.props.isVatZero);
			}

			var validPallets = true;
			for (var i = 0; i < this.props.paletsCount; i++) {
				if (data[`more-info-pallete-${i}-checkbox`]
					&& !data[`more-info-pallete-${i}-input`].isEmpty()) {
					validPallets = validPallets && true;
				} else {
					validPallets = validPallets && false;
				}
			}

			console.log("billingValid:", billingValid);
			console.log("validPallets: ", validPallets);
			return billingValid && validPallets;
		}
		console.log("not valid");
		return false;
	}
}

const mapStateToProps = (state) => {
	return {
		highlightErrors: state.input.highlightErrors,
		inputs: state.input.inputState,
		inputValid: state.input.valid,
		paletsCount: state.imageBlockForm.paletsCount,
		userToken: state.user.token,
		userEmail: state.user.login,
		translations: state.translations,
		pallets: state.translations.pallets,
		countries: state.translations.countrys,
		isVatZero: state.user.isVatZero,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		redirrect: (page, hist) => {
			dispatch(redirrect(page, hist));
		},
		setPopup: (x) => {
			dispatch(setPopup(x));
		},
		setRepositoryValue: (x, y) => {
			dispatch(setValue(x, y));
		},
		setInputValue: (x, y) => {
			dispatch(setInputValue(x, y));
		},
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null)(container));
